<template>
  <div class="mt-40 width_1200">
    <div class="artists">
      <ul>
        <li
          class="hover_shadow"
          :span="6"
          v-for="(s, i) in signContractList"
          :key="i"
        >
          <div class="head">
            <router-link
              :to="{
                path: '/teacher/teacherDetails',
                query: {
                  id: s.ArtistId,
                  type: 3,
                },
              }"
            >
              <img v-lazy="s.HeadImg" class="headImg" alt="" srcset="" />
              {{ s.ArtistName }}
              <img
                v-if="s.IsSign == 1"
                src="@/assets/images/icon_qian.png"
                alt=""
                class="icon_qian"
                srcset=""
              />
            </router-link>
          </div>
          <div class="imgs">
            <img
              v-lazy="a.CoverImg"
              v-for="(a, j) in s.ArtWorks.slice(0, 3)"
              :key="j"
              @click="artClick(a.Id)"
              alt=""
              srcset=""
            />
          </div>
        </li>
      </ul>
      <div v-if="signContractList.length <= 0">
        <no-data></no-data>
      </div>
    </div>
    <div class="width_1200">
      <el-pagination
        v-if="signContractList.length > 0"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import noData from "@/components/noData";
import { queryNewestArtist } from "@/api/art";
export default {
  components: { noData },
  computed: {},
  watch: {},
  data () {
    return {
      pageIndex: 1,
      pageSize: 4,
      total: 0,
      signContractList: []
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    artClick (id) {
      let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : ''
      let url = "/art/artistsDetails?id=" + id + '&artUserId=' + userInfo.Id
      this.$router.push({ path: url });
    },
    async init () {
      let parm = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      const res = await queryNewestArtist(parm);
      if (res.success == true) {
        res.response.data.forEach(element => {
          if (!element.Remark) return
          element.Remark = element.Remark.replace(/<[^>]+>/g, '')
        });
        this.signContractList = res.response.data
        this.total = res.response.dataCount;
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.pageIndex = val;
      this.init();
    },
  }
};
</script>
<style lang="less" scoped>
.artists {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    li {
      flex: 0 0 585px;
      width: 585px;
      height: 286px;
      padding: 30px;
      margin: 0 0 30px 30px;
      background: #ffffff;
      border: 1px solid #e7e7e7;
      // box-shadow: 4px 4px 10px rgba(90, 90, 90, 0.16);
      .head {
        .headImg {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          margin-right: 10px;
        }
        font-size: 18px;
        font-weight: bold;
        .icon_qian {
          height: 14px;
          width: 14px;
          margin-left: 4px;
        }
      }
      .imgs {
        img {
          width: 156px;
          height: 156px;
          margin-left: 27px;
          margin-top: 25px;
          border-radius: 2px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
/deep/.el-pagination {
  text-align: right;
}
</style>
